
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useGetCorporateIdentifiersBasic } from '@/api/corporateIdentifier'
import { useCreateCorporateUnit, useUpdateCorporateUnit } from '@/api/corporateUnit'

import { CorporateUnitOutput, CorporateUnitTreeStructure, CorporateUnitInput } from '@/api/types/corporateUnit'
import { UNIT_TYPE } from '@/api/types/misc'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { cloneDeep } from 'lodash-es'

export default defineComponent({
  name: 'AddEditCorporateUnitDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    corporateUnitToEdit: {
      type: Object as PropType<CorporateUnitOutput>,
      default: null,
    },
  },
  setup: (props, { emit, root }) => {
    const isEditMode = computed(() => Boolean(props.corporateUnitToEdit))

    const { addNotification } = useNotify()

    const {
      exec: getCorporateIdentifiersBasic,
      data: corporateIdentifiers,
      isLoading: isLoadingCorporateIdentifiers,
    } = useGetCorporateIdentifiersBasic()
    getCorporateIdentifiersBasic()

    const form = ref<CorporateUnitInput | CorporateUnitOutput>(
      isEditMode.value ? cloneDeep(props.corporateUnitToEdit) : ({} as CorporateUnitInput)
    )

    const FORM_FIELDS: FormField[] = [
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('baseData.corporateStructure.corporateUnitDialog.form.name') as string),
        ],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'corporateIdentifier',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => corporateIdentifiers.value ?? []),
        isLoading: computed(() => isLoadingCorporateIdentifiers.value),
        isRequired: false,
        rules: [],
      },
    ]

    const { createCorporateUnit, isLoading: isLoadingCreateCorpUnit } = useCreateCorporateUnit()

    async function onAdd(): Promise<void> {
      if (!isOfType<CorporateUnitOutput>(form.value, 'id')) {
        try {
          await createCorporateUnit(form.value)

          addNotification({
            text: root.$t('misc.added') as string,
            type: 'success',
            timeout: 3000,
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateCorporateUnit, isLoading: isLoadingUpdateCorpUnit } = useUpdateCorporateUnit()

    async function onEdit(): Promise<void> {
      if (isOfType<CorporateUnitOutput>(form.value, 'id')) {
        try {
          const updatedForm = mapBasicEntityToIdWithForm<CorporateUnitOutput>(form.value)

          const resCorpUnit = await updateCorporateUnit(updatedForm.id, updatedForm)

          addNotification({
            text: root.$t('misc.edited') as string,
            type: 'success',
            timeout: 3000,
          })
          ;(resCorpUnit as CorporateUnitTreeStructure).unitType = UNIT_TYPE.CORP_UNIT

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditCorpUnit = computed(() => isLoadingCreateCorpUnit.value || isLoadingUpdateCorpUnit.value)

    function close(): void {
      emit('added-edited')
      emit('input', false)
    }

    function onAbort(): void {
      emit('input', false)

      form.value = {} as CorporateUnitInput
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditCorpUnit,

        corporateIdentifiers,
      },
      listeners: {
        onAdd,

        onEdit,

        onAbort,
      },
    })
  },
})
