import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  CorporateIdentifier,
  CorporateIdentifierId,
  NewCorporateIdentifier,
  UseGetCorporateIdentifiers,
  UseGetCorporateIdentifiersBasic,
  UseCreateCorporateIdentifier,
  UseDeleteCorporateIdentifier,
  UseUpdateCorporateIdentifier,
} from './types/corporateIdentifier'

const VERSION = 'v1'
const RESOURCE = 'corporate-identifiers'

function useGetCorporateIdentifiers(): UseGetCorporateIdentifiers {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetCorporateIdentifiers['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetCorporateIdentifiersBasic(): UseGetCorporateIdentifiersBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateCorporateIdentifier(): UseCreateCorporateIdentifier {
  const axios = useAxios<PromiseType<ReturnType<UseCreateCorporateIdentifier['createCorporateIdentifier']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createCorporateIdentifier(data: NewCorporateIdentifier): Promise<CorporateIdentifier> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createCorporateIdentifier,
  }
}

function useUpdateCorporateIdentifier(): UseUpdateCorporateIdentifier {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateCorporateIdentifier['updateCorporateIdentifier']>>>({
    method: 'PUT',
  })

  function updateCorporateIdentifier(
    id: CorporateIdentifierId,
    data: CorporateIdentifier
  ): Promise<CorporateIdentifier> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateCorporateIdentifier,
  }
}

function useDeleteCorporateIdentifier(): UseDeleteCorporateIdentifier {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteCorporateIdentifier['deleteCorporateIdentifier']>>>({
    method: 'DELETE',
  })

  function deleteCorporateIdentifier(id: CorporateIdentifierId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteCorporateIdentifier,
  }
}

export {
  useGetCorporateIdentifiers,
  useGetCorporateIdentifiersBasic,
  useCreateCorporateIdentifier,
  useUpdateCorporateIdentifier,
  useDeleteCorporateIdentifier,
}
