var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-edit-mode": _vm.state.isEditMode,
      "is-loading": _vm.state.isLoadingAddEditCorpUnit
    },
    on: {
      "add": function add($event) {
        _vm.state.isEditMode ? _vm.listeners.onEdit() : _vm.listeners.onAdd();
      },
      "close": function close($event) {
        return _vm.listeners.onAbort();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("baseData.corporateStructure.corporateUnitDialog.".concat(_vm.state.isEditMode ? 'edit' : 'add', ".title"))) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return _vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          var _entry$isLoading$valu, _entry$isLoading;

          return _c('div', {
            key: entry.value
          }, [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXT ? [entry.dataTyp === _vm.constants.DATA_TYPE.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("baseData.corporateStructure.corporateUnitDialog.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()] : entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN ? _c('CommonAutocomplete', {
            class: entry.isRequired && 'required',
            attrs: {
              "items": entry.items.value,
              "item-text": "name",
              "item-value": "id",
              "loading": (_entry$isLoading$valu = (_entry$isLoading = entry.isLoading) === null || _entry$isLoading === void 0 ? void 0 : _entry$isLoading.value) !== null && _entry$isLoading$valu !== void 0 ? _entry$isLoading$valu : false,
              "rules": [].concat(entry.rules),
              "label": _vm.$t("baseData.corporateStructure.corporateUnitDialog.form.".concat(entry.value))
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()], 2);
        });
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }